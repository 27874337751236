import router from 'next/router';
import { Card, Title } from '../UI';
import { Container } from './styles';
import { Larrow } from '../../assets/Homeicons/L-arrow.svg';
import { Rarrow } from '../../assets/Homeicons/R-arrow.svg';
import React, { useEffect, useState } from 'react';
import { useNews } from '../../hooks/news';
import { CategoryNews } from '../../models/news';
import Skeleton from '../Skeleton';

const Newscontainer = () => {
  const { getNewsCategory } = useNews();

  const [newsCategory, setNewsCategory] = useState<CategoryNews[]>([]);
  const [page, setPage] = useState(0);
  const [isLoadingDiv, setIsLoadingDiv] = useState<boolean>(true);

  useEffect(() => {
    async function getInitialData() {
      try {
        const newsCategory = await getNewsCategory(page);
        setNewsCategory(newsCategory);
        setIsLoadingDiv(false);
      } catch (error) {
        setIsLoadingDiv(false);
        console.log(error);
      }
    }

    getInitialData();
  }, []);

  const contentNews = React.useRef(null);

  const sideScroll = (
    element: any,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const handleClick = (slug: string) => {
    router.push({
      pathname: `news/${slug}`,
    });
  };

  const decodeText = (text: string) => {
    return text
      ?.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
      })
      ?.replace(/<p>/g, '')
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<\/p>/g, '');
  };

  return (
    <>
      <Container>
        <div className="title">
          <Title>Quer aprender mais sobre crypto?</Title>
          <div className="arrows">
            <button
              onClick={() => {
                sideScroll(contentNews.current, 10, 200, -10);
              }}
            >
              <Larrow />
            </button>
            <button
              onClick={() => {
                sideScroll(contentNews.current, 10, 200, 10);
              }}
            >
              <Rarrow />
            </button>
          </div>
        </div>

        <div ref={contentNews} className="contentNews">
          <div className="cards">
            {isLoadingDiv
              ? [...Array(5)].map((_, index) => (
                <Card.Box key={index} className="card">
                  <Skeleton className="rowImg" />
                  <Skeleton className="rowTxt" />
                </Card.Box>
              ))
              : newsCategory.map((newsItem, index) => (
                <Card.Box
                  key={index}
                  className="card"
                  onClick={() => handleClick(newsItem.slug)}
                >
                  <img src={newsItem.image} /> <br />
                  <span>{decodeText(newsItem.title)}</span>
                </Card.Box>
              ))}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Newscontainer;
